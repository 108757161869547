<template>
  <div>
    <loader
      object="#ffffff"
      color1="#ffffff"
      color2="#ffffff"
      time="100"
      size="5"
      speed="2"
      bg="#5e151d"
      objectbg="#999793"
      opacity="100"
      :name="selected"
      disableScrolling="false"
    ></loader>
    <div class="container">
      <div class="card">
        <div class="card-header">
          <h1>Perfil de Usuario</h1>
        </div>
        <div class="card-body">
          <div class="mx-1 row">
            <div class="container col-lg-4 col-md-12">
              <b-img
                thumbnail
                fluid
                center
                class="my-3 border"
                rounded="circle"
                v-bind="{ width: 250, height: 250 }"
                src="https://picsum.photos/250/250/?image=54"
                alt="Image 1"
              ></b-img>
            </div>
            <div class="container col-lg-8 col-md-12">
              <div class="my-3">
                <h3>
                  {{ desserts.names }}
                </h3>
                <b-button class="my-3" variant="outline-dark" to="/profile/edit"
                  ><b>Editar perfil</b></b-button
                >
                <h4 class="pb-2">
                  {{ desserts.username }}
                  <em><b-badge style="background: #397232">Activo</b-badge></em>
                </h4>
                <div>
                  <p v-if="desserts.dateBirth != ''">
                    Fecha de nacimiento: <b>{{ desserts.dateBirth }}</b>
                  </p>
                  <p v-else class="text-danger">
                    <em>No hay fecha de nacimiento</em>
                  </p>
                </div>
                <div>
                  <p v-if="desserts.identifications.length != 0">
                    <b-icon-person-badge /> {{ stringIdentifications }}
                  </p>
                  <p v-else class="text-danger">
                    <em>No hay número de identificación</em>
                  </p>
                </div>
                <div>
                  <p v-if="desserts.phones.length != 0">
                    <b-icon-telephone /> {{ stringPhone }}
                  </p>
                  <p v-else class="text-danger">
                    <em>No hay número de celular</em>
                  </p>
                </div>
                <p><b>Roles:</b></p>
                <div class="row mx-1">
                  <h5 v-for="(aux, i) in desserts.roles" :key="i" class="mr-1">
                    <b-badge style="background: #1d425e">{{ aux }}</b-badge>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      selected: "",
      stringPhone: "",
      stringIdentifications: "",
      desserts: { phones: [], identifications: [], roles: [] },
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      this.selected = "circular";
      await this.$axios
        .get("/profile", {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((res) => {
          this.desserts = res.data;
          //Concatenar telefonos
          for (let i = 0; i < res.data.phones.length; i++) {
            if (i > 0) {
              this.stringPhone =
                this.stringPhone + " / " + res.data.phones[i].value;
            } else {
              this.stringPhone = this.stringPhone + res.data.phones[i].value;
            }
          }
          //Concatenar identificaciones
          for (let i = 0; i < res.data.identifications.length; i++) {
            if (i > 0) {
              this.stringIdentifications =
                this.stringIdentifications +
                " / " +
                res.data.identifications[i].value;
            } else {
              this.stringIdentifications =
                this.stringIdentifications + res.data.identifications[i].value;
            }
          }
          this.selected = "";
        })
        .catch((error) => {
          this.$errorQuery(error);
        });
    },
  },
};
</script>